
.top {
    background-color: #1f8a6f32;
    margin-top: 30px;
    margin-bottom: 30px;
}

.shape {
    height: 50px;
    width: 100%;
}

.shape_bottom {
    transform: rotate(180deg);
}

