.main_timeline{
  position: relative;
  transition: all 0.4s ease 0s;
}
.main_timeline:before{
  content: "";
  width: 2px;
  height: 100%;
  background: #bfbfbf;
  position: absolute;
  top: 0;
  left: 50%;
}
.main_timeline .timeline{
  position: relative;
}
.main_timeline .timeline:before,
.main_timeline .timeline:after{
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.main_timeline .timeline_icon{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #1F8A70;
  position: absolute;
  top: 0;
  left: 3px;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
}
.main_timeline .timeline_content{
  width: 46%;
  padding: 20px;
  border-radius: 5px;
  text-align: right;
  /* -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1); */
  /* -ms-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease 0s;
}
.main_timeline .date{
  display: inline-block;
  /* font-size: 16px; */
  /* font-weight: 300; */
  color: #fff;
  padding: 12px 33px;
  background: #1F8A70;
  /* border-radius: 30px; */
  height: 60px;
}

.desktop_mobile_icon{
  mask: url('../public/images/desktop-mobile.svg') no-repeat center;
}

.call{
  mask: url('../public/images/speaking-bubbles-b-w.svg') no-repeat center;
}

.diagnostic{
  mask: url('../public/images/diagnostic.svg') no-repeat center;
}

.quote{
  mask: url('../public/images/euro-price-list.svg') no-repeat center;
}

.installation{
  mask: url('../public/images/toolbox-repairing.svg') no-repeat center;
}

.main_timeline .title{
  font-size: 24px;
  font-weight: 500;
  color: #5c5151;
  margin-top: 10px;
}
.main_timeline .description{
  font-size: 16px;
  color: #606060;
  line-height: 1.5;
}
.main_timeline .timeline_content.right{
  float: right;
  text-align: left;
}

.endImage {
  margin: auto;
  padding-top: 50px;
  width: 35%;
}

.endImage img {
  border-radius: 20px;
}

@media only screen and (max-width: 767px){
  .main_timeline:before{
      left: 0;
  }
  .main_timeline .timeline_icon{
      left: -8px;
      margin: 0;
  }
  .main_timeline .timeline_content{
      width: 90%;
      float: right;
      text-align: left;
  }

  .endImage {
    width: 75%;
  }

}