.container {
    margin-top: 25px;
}

.brandColor {
    color: #1F8A70;
}

.icon div {
    height: 60px;
    background-color: #1F8A70;
}

.icon .evChargerIcon {
    /* -webkit-mask: url(../public/images/ev-charger.svg) no-repeat center; */
    mask: url('../public/images/ev-charger.svg') no-repeat center;
}

.icon .solutions {
    /* -webkit-mask: url(../public/images/ev-charger.svg) no-repeat center; */
    mask: url('../public/images/services.svg') no-repeat center;
}

.icon .power {
    /* -webkit-mask: url(../public/images/ev-charger.svg) no-repeat center; */
    mask: url('../public/images/spark.svg') no-repeat center;
}

.col p b {
    color: #1F8A70;
}