
.top {
    background-color: #DCECF3;
    /* background-color: #1f8a6f32; */
    margin-top: 60px;
    margin-bottom: 00px;
}

.shape {
    height: 50px;
    width: 100%;
}

.shape_bottom {
    transform: rotate(180deg);
}

.shape_top {
    transform: rotateY(180deg);
}

.list {
    list-style-type: none;
    counter-reset: step-counter;
}

.list li {
    counter-increment: step-counter;
    position: relative;
    margin-bottom: 10px;
}

.list li::before {
    position: absolute;
    margin-right: 10px;
    content: counter(step-counter);
    background: white;
    border-radius: 50%;
    color: #5c9bb7;
    width: 25px;
    text-align: center;
    display: inline-block;
    left: -40px;
}