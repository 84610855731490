@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.masterhead {
    position: relative;
    height: 100hv;
    min-height: 40rem;
    padding-top: 100px;
    padding-left: 100px;
    // background-image: url('../public/images/car_charging.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    overflow: hidden;
}

.header {
    position: relative;
    min-height: 75vh;
    align-items: center;
    overflow: hidden;
}

.headerText {
    min-height: 75vh;
    display: flex;
    margin-top: 30px;
}

.title {
    font-size: 50px;
}

.obliqueDiv {
    transform: skewX(-10deg);
    overflow: hidden;
    width: 60%;
    right: -10rem;
    border-bottom-left-radius: 0.75rem;
    
}

.backgroundImage {
    z-index: 0;
    background-size: cover;
    background-position-x: -150px;
    background-position-y: 20%;
    background-image: url('../public/images/car_charging.jpg');
    transform: skewX(10deg);
    margin-left: -4.1rem !important;
    background-repeat: no-repeat;
}
  

.svgRoundedBorder > svg{
    display: none;
}

@include media-breakpoint-down(md) {

    .header {
        // background-color: #e0ecf1;
        // background-image: url('../public/images/car_charging.jpg');
        // background-repeat: no-repeat;
        background: linear-gradient(0deg, rgba(255,255,255,0.75),rgba(255,255,255,0.75)), url('../public/images/car_charging_mobile.jpg') no-repeat center center ;
        background-size: cover;
    }

    .svgRoundedBorder > svg{
        height: 30px;
        width: 100%;
        fill: white;
        display: block !important;
    }

    .iconScroll {
        margin-top: 30px;
        width: 35px;
        height: 60px;
        box-shadow: inset 0 0 0 1px gray;
        border-radius: 25px;
        margin-left: -20px;
    }
    
    .iconScroll, .iconScroll::before {
        position: absolute;
        left: 50%;
        top: 80%;
    }
    
    .iconScroll::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: gray;
        margin-left: -4px;
        top: 42px;
        animation-name: scroll;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    .title {
        font-size: 40px;
    }
    
    @keyframes scroll {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(-42px);
        }
    
    }

}

@include media-breakpoint-up(lg) {
    .masterhead::after, .masterhead::before{
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 75%;
        height: 100hv;
        min-height: 40rem;
        background-color: white;
        transform: skew(342deg)
                    translateX(-100%);
        opacity: 1;
        z-index: 1;
        
    }
    
    
    .masterhead * {
        z-index: 100;
    }
}

.brand {
    color: #1F8A70;
}